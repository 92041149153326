import { trigger, useLocalStorage } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Hook that handles the user's primary address in local storage.
 *
 * @returns {Object} Available methods to manage the address.
 * @returns {Function} showMyFirstAddress - Triggers the store locator event if the address is "require".
 * @returns {Function} setRequire - Sets the address to "require".
 */
export const useMyFirstAddress = () => {
  const [myFirstAddress, setMyFirstAddress] = useLocalStorage(
    'myFirstAddress',
    'pass'
  )

  const showMyFirstAddress = () => {
    if (myFirstAddress === 'require') {
      trigger({
        data: {
          show: true
        },
        eventType: 'storeLocator'
      })
    }

    setMyFirstAddress('pass')
  }

  const setRequire = () => {
    setMyFirstAddress('require')
  }

  return {
    showMyFirstAddress,
    setRequire
  }
}
