import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Column, Container, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import {
  generateBreadcrumbJoin,
  useCategories,
  useContentful,
  usePagination,
  UseQueryResult,
  useProductsIntelligenceSearch,
  useProgressiveLoading,
  useQueryClient
} from '@smu-chile/pkg-unimarc-hooks'
import {
  promotionsOfShelves,
  categoriesForBreadcrumbs,
  changeUrlCategories,
  contentBannerLegal
} from '../../shared/helpers'
import {
  BodyPageProducts,
  BodyPageProductsProps,
  ErrorPage,
  LegalTerms,
  MonetiseBanner
} from '..'
import { paginationCal } from 'shared/helpers/paginationCal'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsIntelligenceSearch'
import { REACT_QUERY_GENERAL } from 'shared/helpers/constants/reactQuery'

interface ProductListPageProps {
  categories: Array<string>
  isFallBack?: boolean
  page: number
}

export const ProductListPage = ({ categories, page }: ProductListPageProps) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { offset, end } = paginationCal({ page, limit: 50 })
  const [offSet, setOffSet] = useState(offset)
  const [limit, setLimit] = useState(end)
  // get all promotions available in contenful
  const resultPromotions = useContentful({
    options: { content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE }
  })
  // clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })
  // Get all categories
  const resultAisles = useCategories({
    level: 2,
    site: 'alvi',
    reactQuery: { queryKey: 'PLP' }
  })

  // Get a clean object of categories
  const { breadcrumbData, subCategories, title, categoryId } =
    categoriesForBreadcrumbs({ router, categories, resultAisles })

  const dataBreadCrumb = changeUrlCategories(breadcrumbData)

  // get data to banner legal
  const contentCategories = useContentful({
    options: {
      content_type: 'plpBannerLegal',
      'fields.referenceType': 'category',
      'fields.referencia': categoryId,
      'fields.idFormato': 2
    },
    reactQuery: { enabled: Boolean(categoryId) }
  })
  const { bannerImages, contentLegalParagraph, contentLegalTitle } =
    contentBannerLegal(contentCategories?.data)

  const urlLinkCateory =
    contentCategories?.data?.['items'][0]?.fields?.urlRedirect

  const setProductsRange = (noPage: number) => {
    const { offset, end } = paginationCal({ page: noPage, limit: 50 })
    setOffSet(offset)
    setLimit(end)
  }

  const productsByCategory = useProductsIntelligenceSearch({
    from: offSet,
    to: limit,
    categories: categories?.join('/'),
    field: categories?.join('/'),
    hideUnavailableItems: 1,
    reactQuery: {
      ...REACT_QUERY_GENERAL,
      queryKey: [offSet, limit],
      enabled: !queryClient.getQueryData(
        `productsIntelligenceSearch_${categories?.join('/')}`
      )
    }
  }) as UseQueryResult<Data>
  const products = [
    ...(productsByCategory?.data?.availableProducts || []),
    ...(productsByCategory?.data?.notAvailableProducts || [])
  ]
  const totalQuantity: number = +productsByCategory?.data?.resources || 0
  const loadAndIncrementBy = 4
  const isLoadingPage = resultAisles.isLoading || productsByCategory.isLoading
  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: setProductsRange
  })

  // custom hook to load more content progressively
  const { visibleEntries } = useProgressiveLoading({
    totalEntries: totalQuantity,
    initialCount: loadAndIncrementBy,
    incrementBy: loadAndIncrementBy,
    rootMargin: '100px',
    threshold: 0.1
  })
  const dataOfBodyPageProducts: BodyPageProductsProps = {
    visibleEntries,
    breadcrumbData: dataBreadCrumb,
    dataPromotions,
    isLoadingPage,
    products,
    propsNavigation,
    subCategories,
    title,
    totalQuantity
  }

  useEffect(() => {
    propsNavigation.setPage(page)
    const { offset, end } = paginationCal({ page, limit: 50 })
    setOffSet(offset)
    setLimit(end)
  }, [categories])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  if (
    (products?.length <= 0 && !isLoadingPage) ||
    productsByCategory?.isError
  ) {
    return (
      <ErrorPage
        errorType='404'
        redirect={false}
      />
    )
  }
  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-alvi-neutral-gray-white')}
      justifyContent='center'
      tagName='main'
    >
      <BigScreen>
        <Column alignItems='center'>
          {resultAisles.data && !isLoadingPage && (
            <MonetiseBanner
              images={bannerImages}
              padding='32px 0 0 0'
              urlLink={urlLinkCateory}
            />
          )}
          {!bannerImages && <Spacer.Horizontal size={24} />}
          <Container
            backgroundColor={getGlobalStyle('--color-alvi-neutral-gray-white')}
            customWidth='90'
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='12px 36px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
          </Container>
          <LegalTerms
            paragraph={contentLegalParagraph}
            title={contentLegalTitle}
          />
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column
          backgroundColor={getGlobalStyle('--color-alvi-neutral-gray-white')}
        >
          {!bannerImages ? (
            <Spacer.Horizontal size={32} />
          ) : (
            <Spacer.Horizontal size={48} />
          )}
          {bannerImages && (
            <MonetiseBanner
              images={bannerImages}
              padding='8px 12px'
              urlLink={urlLinkCateory}
            />
          )}
          <BodyPageProducts {...dataOfBodyPageProducts} />
          <LegalTerms
            paragraph={contentLegalParagraph}
            title={contentLegalTitle}
          />
        </Column>
      </SmallScreen>
    </Container>
  )
}
