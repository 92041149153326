import {
  Breadcrumbs,
  Row,
  Title,
  Text,
  BackboneShelves,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { Subcategories, CustomPagination, Shelves } from 'components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import { clickPlp } from 'shared/utils/googleAnalytics'
import styles from './BodyPageProducts.module.css'

type BreadcrumbsData = {
  label: string
  url?: string
  oneClick?: (url: string) => void
}

type SubcategoriesProp = {
  label: string
  url: string
}

type propsNavigation = {
  currentPage: number
  pageQuantity: number
  isVisableButtonLeft: boolean
  isVisableButtonRight: boolean
  isVisableButtons: boolean
  items: (string | number)[]
  nextPage: () => void
  prevPage: () => void
  setPage: (value: number) => void
}

export interface BodyPageProductsProps {
  visibleEntries: number
  breadcrumbData?: BreadcrumbsData[]
  title: string
  totalQuantity: string | number
  subCategories?: SubcategoriesProp[]
  propsNavigation: propsNavigation
  isLoadingPage: boolean
  products: AvailableProduct[] | Product[]
  dataPromotions: unknown[]
}

export const BodyPageProducts = ({
  breadcrumbData,
  title,
  totalQuantity,
  subCategories = [],
  propsNavigation,
  isLoadingPage = true,
  products,
  dataPromotions,
  visibleEntries
}: BodyPageProductsProps) => {
  // use.
  const { isMobile } = useMobile()
  // primitive
  const fontSizeQuantity = isMobile ? 'sm' : 'base'
  const justifyPaginationEnd = isMobile ? 'center' : 'end'

  const Pagination = () => {
    return (
      <CustomPagination
        {...{ ...propsNavigation, isLoadingPage }}
        backgroundCurrent={getGlobalStyle('--color-alvi-primary-blue3')}
      />
    )
  }

  const CustomHeader = () => {
    return (
      <>
        <Row>
          <Breadcrumbs
            afterClick={(label) => {
              clickPlp(label, 'breadcrumb')
            }}
            colorAlvi
            links={breadcrumbData}
          />
        </Row>
        <Spacer.Horizontal size={12} />
        <Row alignItems='baseline'>
          <Row
            justifyContent='start'
            width='auto'
          >
            <Title
              fontWeight='semibold'
              headingLevel='h4'
              size='small'
            >
              {title}
            </Title>
          </Row>
          <Spacer.Vertical size={8} />
          <Text
            color='gray'
            customClassName={styles.resultText}
            fontSize={fontSizeQuantity}
          >
            ({totalQuantity} resultados)
          </Text>
        </Row>
      </>
    )
  }
  return (
    <>
      {isMobile ? (
        <Row
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-alvi-neutral-gray-white')}
          isWrap
          padding='12px'
        >
          <CustomHeader />
        </Row>
      ) : (
        <CustomHeader />
      )}
      {subCategories?.length > 0 && <Subcategories data={subCategories} />}
      {!isMobile && (
        <>
          <Spacer.Horizontal size={12} />
          <Row justifyContent='end'>
            <Pagination />
          </Row>
          <Spacer.Horizontal size={32} />
        </>
      )}
      <Row>
        {isLoadingPage ? (
          <BackboneShelves
            horizontal
            shelvesForRow={4}
            shelvesQuantityDesktop={12}
          />
        ) : (
          <>
            <BigScreen>
              <Shelves
                customWidthQuantityButton='100%'
                dataPromotions={dataPromotions}
                isMobile={isMobile}
                orientation='vertical'
                padding='0'
                products={products}
                visibleEntries={visibleEntries}
              />
            </BigScreen>
            <SmallScreen>
              <>
                <Shelves
                  customWidthQuantityButton='170px'
                  dataPromotions={dataPromotions}
                  isMobile={isMobile}
                  orientation='horizontal'
                  padding='0 15px'
                  products={products}
                  visibleEntries={visibleEntries}
                />
              </>
            </SmallScreen>
          </>
        )}
      </Row>
      <Spacer.Horizontal size={24} />
      <Row justifyContent={justifyPaginationEnd}>
        <Pagination />
      </Row>
      <Spacer.Horizontal size={24} />
    </>
  )
}
