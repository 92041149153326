import { rest } from 'msw'
import { render } from '@testing-library/react'
import {
  QueryClient,
  QueryClientProvider
} from '@smu-chile/pkg-unimarc-hooks/reactQuery'

export const handlers = [
  rest.get('*/*', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        response: 'default'
      })
    )
  }),
  rest.post('*/*', (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        response: 'default'
      })
    )
  })
]

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})

export function renderWithClient(ui: React.ReactElement) {
  const { rerender, ...result } = render(
    <QueryClientProvider client={queryClient}>{ui}</QueryClientProvider>
  )

  return {
    ...result,
    rerender: () => {
      return rerender(
        <QueryClientProvider client={queryClient}>{ui}</QueryClientProvider>
      )
    }
  }
}

export const createWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export const createChildrenMock = (content: string) => {
  return <span>{content}</span>
}
