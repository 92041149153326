import { useEffect } from 'react'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Column, Container, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  generateBreadcrumbJoin,
  usePagination,
  useProgressiveLoading,
  useProducts
} from '@smu-chile/pkg-unimarc-hooks'
import { contentBannerLegal, promotionsOfShelves } from '../../shared/helpers'
import {
  BodyPageProducts,
  BodyPageProductsProps
} from 'components/BodyPageProducts'
import { ErrorPageSearch } from 'components/ProductSearchPage/ErrorPageSearch'
import { OffersProps } from 'pages/ofertas/[...offers]'
import { MonetiseBanner, LegalTerms } from 'components'
import { paginationCal } from 'shared/helpers/paginationCal'
import { REACT_QUERY_GENERAL } from 'shared/helpers/constants/reactQuery'
import { queryClient } from 'shared/utils/test/utils'

export const ProductOfferPage = ({
  idCollection,
  page,
  promotions,
  title
}: OffersProps) => {
  const dataBreadCrumb = [
    {
      label: 'Inicio',
      url: '/',
      oneClick: () => {
        return null
      }
    },
    { label: `“${title}”`, url: '#' }
  ]
  generateBreadcrumbJoin(dataBreadCrumb)
  const { offset, end } = paginationCal({ page, limit: 50 })
  const searchProduct = useProducts({
    fq: idCollection,
    to: end,
    from: offset,
    site: 'alvi',
    reactQuery: {
      ...REACT_QUERY_GENERAL,
      queryKey: [offset, end],
      enabled: !queryClient.getQueryData([
        `products_${idCollection}`,
        [offset, end]
      ])
    }
  })

  // Get all promotions available in contenful
  const { bannerImages, contentLegalParagraph, contentLegalTitle } =
    contentBannerLegal(searchProduct?.data?.bannerLegal)
  const urlLinkOffer =
    searchProduct?.data?.bannerLegal?.items[0]?.fields?.urlRedirect

  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: promotions })
  const products = searchProduct?.data?.products
  const totalQuantity = parseInt(searchProduct?.data?.resources) || 0
  const loadAndIncrementBy = 4

  const setProductsRange = (next: number) => {
    return next
  }
  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: setProductsRange
  })

  // custom hook to load more content progressively
  const { visibleEntries } = useProgressiveLoading({
    totalEntries: totalQuantity,
    initialCount: loadAndIncrementBy,
    incrementBy: loadAndIncrementBy,
    rootMargin: '100px',
    threshold: 0.1
  })
  const dataOfBodyPageProducts: BodyPageProductsProps = {
    visibleEntries,
    breadcrumbData: dataBreadCrumb,
    title,
    totalQuantity,
    propsNavigation,
    isLoadingPage: false,
    products,
    dataPromotions
  }

  useEffect(() => {
    setProductsRange(page)
  }, [])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  const Main = ({ children }: { children: React.ReactNode }) => {
    return (
      <Container
        alignItems='start'
        backgroundColor={getGlobalStyle('--color-background-white')}
        justifyContent='center'
        tagName='main'
      >
        {children}
      </Container>
    )
  }

  if (totalQuantity == 0) {
    return (
      <Main>
        <ErrorPageSearch
          message='resultados para'
          search={title}
        />
      </Main>
    )
  }

  return (
    <Main>
      <BigScreen>
        <Column alignItems='center'>
          {bannerImages && (
            <MonetiseBanner
              images={bannerImages}
              padding='32px 0 0 0'
              urlLink={urlLinkOffer}
            />
          )}
          {!bannerImages && <Spacer.Horizontal size={24} />}
          <Container
            backgroundColor={getGlobalStyle('--color-background-white')}
            customWidth='90'
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='12px 36px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
            <LegalTerms
              paragraph={contentLegalParagraph}
              title={contentLegalTitle}
            />
          </Container>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column backgroundColor={getGlobalStyle('--color-neutral-gray-light')}>
          {!bannerImages ? (
            <Spacer.Horizontal size={32} />
          ) : (
            <Spacer.Horizontal size={48} />
          )}
          {bannerImages && (
            <MonetiseBanner
              images={bannerImages}
              padding='8px 12px'
              urlLink={urlLinkOffer}
            />
          )}
          <BodyPageProducts {...dataOfBodyPageProducts} />
          <LegalTerms
            paragraph={contentLegalParagraph}
            title={contentLegalTitle}
          />
        </Column>
      </SmallScreen>
    </Main>
  )
}
