import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  generateBreadcrumbJoin,
  useContentful,
  usePagination,
  useProgressiveLoading,
  useProductsIntelligenceSearch
} from '@smu-chile/pkg-unimarc-hooks'
import { changeUrlCategories, promotionsOfShelves } from '../../shared/helpers'
import { dataForBreadcrumbs } from 'shared/helpers'
import { ErrorPageSearch } from '../ProductSearchPage/ErrorPageSearch'
import {
  SearchBodyProduct,
  Main
} from 'components/ProductSearchPage/SearchBodyProduct'
import { BodyPageProductsProps } from 'components/BodyPageProducts'
import { IOptions } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { queryClient } from 'shared/utils/test/utils'
import { REACT_QUERY_GENERAL } from 'shared/helpers/constants/reactQuery'
import { paginationCal } from 'shared/helpers/paginationCal'

interface ProductBrandPros {
  page: number
  search: string
  options?: IOptions
}

export const ProductBrandPage = ({
  options,
  search,
  page
}: ProductBrandPros) => {
  const router = useRouter()
  const { breadcrumbData, title } = dataForBreadcrumbs({
    router,
    search,
    brand: true
  })
  const dataBreadCrumb = changeUrlCategories(breadcrumbData)
  const { offset, end } = paginationCal({ page, limit: 50 })

  const intelliSearchData = useProductsIntelligenceSearch({
    ...options,
    field: options.brand,
    reactQuery: {
      ...REACT_QUERY_GENERAL,
      queryKey: [offset, end],
      enabled: !queryClient.getQueryData([
        `productsIntelligenceSearch_${options?.brand}`,
        [offset, end]
      ])
    }
  })
  generateBreadcrumbJoin(dataBreadCrumb)

  // Get all promotions available in contenful
  const resultPromotions = useContentful({
    options: { content_type: process?.env?.NEXT_PUBLIC_CAMPAIGN_TYPE }
  })
  // Clean the object of promtions
  const dataPromotions = promotionsOfShelves({ data: resultPromotions?.data })
  const { availableProducts, notAvailableProducts } =
    intelliSearchData?.data || {}
  const products = [
    ...(availableProducts || []),
    ...(notAvailableProducts || [])
  ]

  const totalQuantity = parseInt(intelliSearchData?.data?.resources) || 0
  const loadAndIncrementBy = 4
  const isLoadingPage =
    intelliSearchData?.isLoading || resultPromotions?.isLoading

  const setProductsRange = (next: number) => {
    return next
  }

  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: setProductsRange
  })

  // custom hook to load more content progressively
  const { visibleEntries } = useProgressiveLoading({
    totalEntries: totalQuantity,
    initialCount: loadAndIncrementBy,
    incrementBy: loadAndIncrementBy,
    rootMargin: '100px',
    threshold: 0.1
  })
  const dataOfBodyPageProducts: BodyPageProductsProps = {
    visibleEntries,
    breadcrumbData: dataBreadCrumb,
    title,
    totalQuantity,
    propsNavigation,
    isLoadingPage,
    products,
    dataPromotions
  }
  useEffect(() => {
    setProductsRange(page)
  }, [])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  if (!isLoadingPage && totalQuantity == 0) {
    return (
      <Main>
        <ErrorPageSearch
          message='resultados para la marca'
          search={search}
        />
      </Main>
    )
  }

  return <SearchBodyProduct {...dataOfBodyPageProducts} />
}
