import {
  getCategories,
  getIntelligenceSearch,
  QueryClient,
  getProductsIntelligenceSearch
} from '@smu-chile/pkg-unimarc-hooks'
import { Data } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICategories'
import { GetServerSidePropsContext } from 'next'
import { paginationCal } from './paginationCal'
import { getHeaderCookies } from './getHeaderCookies/getHeaderCookies'

interface GetServerSidePropsCategory {
  categories: string | string[]
  categoryData: Data
  intelliSearchData?: null
  error: boolean
  queryClient: QueryClient
}

export const normalizeCategory = (category: string) => {
  return category
    .normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/,/g, '')
    ?.replace(/\s/g, '-')
    .toLowerCase()
}

export const changeUrlCategories = (breadcrumbData) => {
  const breadcrumb = breadcrumbData?.map?.((breadcrumbItem) => {
    if (breadcrumbItem?.label !== 'Inicio') {
      breadcrumbItem['url'] = `/category${breadcrumbItem?.url}`
    }
    return breadcrumbItem
  })

  breadcrumb[breadcrumbData?.length - 1]['url'] = '#'
  const { label, url } = breadcrumb[breadcrumbData?.length - 1]
  breadcrumb[breadcrumbData?.length - 1] = {
    label,
    url
  }
  return breadcrumb
}

export const categoriesForBreadcrumbs = ({
  router,
  categories,
  resultAisles
}) => {
  let urlCategory = ''
  let categoryId = ''
  let subCategories = []
  let categoryTemp = resultAisles?.data || []
  const handleOnClickBreadCrumbs = (url: string) => {
    return router.push(url)
  }

  const breadcrumbData =
    categories?.map((key) => {
      const data = categoryTemp?.find(({ name }) => {
        return normalizeCategory(name) === normalizeCategory(key)
      })
      categoryId = data?.id
      categoryTemp = data?.children.length > 0 ? data.children : data
      urlCategory += `/${key}`
      subCategories = data?.children?.map(({ name }) => {
        return {
          label: name,
          url: `${urlCategory}/${normalizeCategory(name)}`
        }
      })

      return {
        label: data?.name ?? '',
        url: urlCategory,
        oneClick: handleOnClickBreadCrumbs
      }
    }) || []
  breadcrumbData?.unshift({
    label: 'Inicio',
    url: '/',
    oneClick: handleOnClickBreadCrumbs
  })
  const title = breadcrumbData[breadcrumbData?.length - 1].label

  return {
    breadcrumbData,
    subCategories,
    title,
    categoryId
  }
}

export const getServerSidePropsCategory = async (
  context: GetServerSidePropsContext,
  page: number
): Promise<GetServerSidePropsCategory> => {
  const queryClient = new QueryClient()
  const checkQueryArray = Array.isArray(context?.query?.categories)
    ? context?.query?.categories
    : [context?.query?.categories]
  const { offset, end } = paginationCal({ page, limit: 50 })

  // get categories and save it in temporal variable
  const categories = (await getCategories(2, 'alvi')) as Data[]
  let temporalCategories = categories

  // we extract the category match id here, e.g 349 (meet)
  const checkCategory: Data = checkQueryArray
    .map((key) => {
      const categoryMatch = temporalCategories?.find(({ name }) => {
        return normalizeCategory(name) === normalizeCategory(key)
      })
      temporalCategories =
        categoryMatch?.children.length > 0
          ? categoryMatch?.children
          : [categoryMatch]

      return categoryMatch
    })
    .slice(-1)[0]

  // if there is no data then an error is triggered
  if (!categories || categories.length === 0) {
    return {
      categories: context.query.categories,
      categoryData: null,
      intelliSearchData: null,
      error: true,
      queryClient
    }
  }
  const headers = getHeaderCookies(context)
  if (checkCategory) {
    const categoryUrl = handleCategoryUrl({ url: checkCategory.url })
    const prodOptions = {
      categories: categoryUrl,
      from: offset,
      headers,
      to: end,
      hideUnavailableItems: 1 as 0 | 1
    }

    await queryClient.prefetchQuery(
      [`productsIntelligenceSearch_${categoryUrl}`, [offset, end]],
      () => {
        return getProductsIntelligenceSearch(prodOptions)
      }
    )
  }

  return {
    categories: context.query.categories,
    categoryData: { ...checkCategory },
    error: false,
    queryClient
  }
}

export const getSearchData = async ({ query, page, context }) => {
  const queryClient = new QueryClient()
  const { offset, end } = paginationCal({ page, limit: 50 })
  const intelliSearchKeyName = [`intelligencesearch_${query}`, [offset, end]]
  const headers = getHeaderCookies(context)
  const intelliOptions = {
    search: query.trim(),
    from: offset,
    headers,
    to: end
  }

  await queryClient.prefetchQuery(intelliSearchKeyName, () => {
    return getIntelligenceSearch(intelliOptions)
  })
  const intelliSearchData = queryClient.getQueryData(intelliSearchKeyName)

  return {
    intelliSearchData,
    queryClient
  }
}

const handleCategoryUrl = ({ url = '' }) => {
  if (/\.com\.br\/(.+)/.test(url)) return /\.com\.br\/(.+)/.exec(url)[1]

  return url
}
