import { GetServerSidePropsContext } from 'next'
import { AxiosRequestHeaders } from '@smu-chile/pkg-unimarc-hooks'

export const getHeaderCookies = (
  context: GetServerSidePropsContext
): AxiosRequestHeaders => {
  let headers = {}
  const cookies = context?.req?.cookies
  if (cookies?.co_vtex_segment) {
    headers = {
      Cookie: `co_vtex_segment=${cookies?.co_vtex_segment?.toString()}`
    }
  }
  return headers
}
