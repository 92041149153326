import { useEffect, useMemo, useState } from 'react'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks/hooks/useContentful'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks/hooks/useMobile'

export const useOfferLink = () => {
  const mobile = useMobile()

  const [offerPicture, setOfferPicture] = useState()
  const [offerUrl, setOfferUrl] = useState()

  const offerImage = useContentful({
    id_contentful: 'offerImage',
    options: {
      'fields.title[in]': 'Ofertas mobile'
    },
    reactQuery: {
      enabled: mobile.isMobile,
      refetchOnWindowFocus: false
    },
    type: 'assets'
  })

  const offerMenu = useContentful({
    id_contentful: 'offerMenu',
    options: {
      'sys.contentType.sys.id[in]': 'home',
      'sys.id[in]': '4JOyVsXlRL5xVbw4APQBbD'
    },
    reactQuery: {
      refetchOnWindowFocus: false
    }
  })

  const offerLink = useMemo(() => {
    if (offerPicture && offerUrl) {
      return {
        img: offerPicture,
        url: offerUrl
      }
    }

    return null
  }, [offerPicture, offerUrl])

  useEffect(() => {
    if (offerImage.isSuccess) {
      setOfferPicture(offerImage.data?.['items'][0]?.fields?.file?.url)
    }
  }, [offerImage.data])

  useEffect(() => {
    if (offerMenu.isSuccess) {
      setOfferUrl(offerMenu.data?.['includes']?.['Entry']?.[0]?.fields?.text)
    }
  }, [offerMenu.data])

  return {
    offerPicture,
    offerLink,
    offerUrl
  }
}
