import { ChangeEvent, useEffect, useMemo, useReducer, useState } from 'react'
import { useRouter } from 'next/router'
import {
  AddressTypeProp,
  currencyFormat,
  datalayerSimpleEvent,
  eventClick,
  getAddressAlvi,
  postNewAddress,
  simulateCart,
  sleep,
  useAlviStores,
  useCart,
  useEvents,
  useKeyPress,
  useMobile,
  useTrigerEvent,
  useUpdateAddressAlvi
} from '@smu-chile/pkg-unimarc-hooks'
import { Site } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/getSite'
import { IStore } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IStoresAlvi'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { NewAddressData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewAddressData'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete'
import {
  AddressListPropsAlvi,
  ButtonStatus,
  StoreLocatorAlvi,
  StoreLocatorPropsAlvi,
  TitleProps,
  ValuesProps
} from '@smu-chile/pkg-unimarc-components'
import { UserAddressListProps } from '@smu-chile/pkg-unimarc-components/stories/organisms/StoreLocator/ModalState/AddressModal/AddressModalContent'
import { addressFormat } from 'shared/utils/addressFormated/address'
import { directionCoverage } from './helpers/directionCoverage'
import { formatDate } from 'shared/utils/formatDate'
import { handleReducer, initialState } from './helpers/reducer'
import { Map } from './Map'
import { setPageLocation } from 'shared/helpers'
import { validateStoreLocatorAvailableRoute } from './helpers/validateStoreLocatorAvailableRoute'

export interface AddressMaps {
  city: string
  country: string
  neighborhood: string
  number: string
  place_id: string
  postalCode: string
  state: string
  street: string
}

export interface CustomAddressSelectorProps extends UserAddressListProps {
  addressId: string
  geoCoordinates?: number[]
}

export const StoreLocatorComponent = (): React.ReactElement => {
  const CHANGE_ADDRESS = 1
  const SHOW_ADDRESS_LIST = 2
  const SET_ADDRESS_WITHOUT_COVERAGE = 3
  const SAVE_ADDRESS_WITHOUT_COVERAGE = 4
  const productDummy = [
    {
      quantity: '1',
      seller: '1',
      id: process.env.NEXT_PUBLIC_PRODUCT_DUMMY || ''
    }
  ]
  const site = 'alvi'
  const router = useRouter()
  const [arrFail, setFail] = useState({ failed: [] })
  const [active, setAction] = useState<number>(CHANGE_ADDRESS)
  const [address, setAddress] = useState<string>('')
  const [addressList, setAddressList] = useState([])
  const [addressSelected, setAddressSelected] = useState(null)
  const [directionModal, setDirectionModal] = useState<boolean>(false)
  const [errorAddress, setErrorAddress] = useState<boolean>(false)
  const [errorMapMessage, setErrorMapMessage] = useState<string>('')
  const [errorMobileModal, setErrorFailMobile] = useState<boolean>(false)
  const [errorType, setErrorType] = useState<string>('')
  const [failModal, setFailModal] = useState<boolean>(false)
  const [idAddressCurrent, setIdAddressCurrent] = useState<boolean | null>(true)
  const [isOpenModalOutStock, setOpenModalOutStock] = useState(false)
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false)
  const [mapModal, setMapModal] = useState<boolean>(false)
  const [mapsAddress, setMapsAddress] = useState<AddressMaps>(null)
  const [saveWithOutCoverage, setSaveWithOutCoverage] = useState(null)
  const [values, setValues] = useState<ValuesProps>({ search: '' })
  const [sla, setSla] = useState('')
  const [screenHeight, setScreenHeight] = useState('0px')
  const [storeList, setStoreList] = useState<IStore[]>([])
  const arrowDownPressed = useKeyPress('ArrowDown')
  const arrowUpPressed = useKeyPress('ArrowUp')
  const memoValues = useMemo<ValuesProps>(() => {
    return values
  }, [values])
  const {
    handleAddressObject,
    data: updateData,
    isLoading,
    error
  } = useUpdateAddressAlvi()
  const {
    suggestions: { data },
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'cl' }
    }
  })
  const searchingAddress = memoValues.search && memoValues.search.length >= 3
  const [state, dispatch] = useReducer(handleReducer, initialState)
  const buttonStatus = state.buttonStatus as ButtonStatus
  const { trigger } = useTrigerEvent()
  const { isMobile } = useMobile()
  const typeDeliver = router.query['delivery-type'] as string
  const {
    data: orderFormData,
    isFetched,
    isLoading: loadingOrderForm
  } = useCart()

  // Is delivery depends the kind of address
  // delivery = addressType : "residential"
  // pick up store  = addressType : "pickup-in-point"
  const addressType = orderFormData?.address?.addressType as AddressTypeProp
  const isAddressSelected = Boolean(
    orderFormData?.address && orderFormData?.address?.street
  )
  const isDeliveryValidation =
    typeof addressType === 'undefined' || addressType === 'residential'
  const initialIsdelivery = isDeliveryValidation || isAddressSelected
  const [isDelivery, setIsDelivery] = useState(initialIsdelivery)
  const addressTypeToSend = isDelivery
    ? 'residential'
    : ('pickup-in-point' as AddressTypeProp)
  const selectedAddresses = orderFormData?.address
  const geocoordinates =
    selectedAddresses?.geoCoordinates?.join(';') || '-70.74049;-33.474895'
  const avliStores = useAlviStores({
    geocoordinates,
    reactQuery: {
      queryKey: geocoordinates
    }
  })
  const stores = avliStores.data?.stores || []
  const setWithoutCoverage =
    saveWithOutCoverage === SET_ADDRESS_WITHOUT_COVERAGE
  const saveWithoutCoverage =
    saveWithOutCoverage === SAVE_ADDRESS_WITHOUT_COVERAGE
  const showSubtitleList =
    storeList.length > 1 ||
    (storeList.length === 1 &&
      storeList?.[0].pickupPoint?.address?.selected === false)

  const cleanUrlParams = () => {
    const queryParams = router.query
    delete queryParams?.['delivery-type']
    router.push(
      {
        href: window.location.href,
        query: queryParams
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const resetAddressAndStore = () => {
    const newAddressList = addressList.map((address) => {
      const selected = address.id === orderFormData?.address.id
      return { ...address, selected }
    })

    const newStoreList = storeList.map((address) => {
      const selected =
        address.pickupPoint.address.addressId ===
        orderFormData?.address?.addressId
      address.pickupPoint.address.selected = selected
      return address
    })

    setStoreList(newStoreList)
    setAddressList(newAddressList)
  }

  const handleModal = () => {
    if (isLoading) return
    if (typeDeliver === 'delivery') {
      cleanUrlParams()
    }

    resetAddressAndStore()
    setIsDelivery(isDeliveryValidation)
    trigger({ eventType: 'storeLocator', data: { show: false } })
    setDirectionModal(false)
    setFailModal(false)
    setErrorFailMobile(false)
    setMapModal(false)
    setValues({})
  }

  // just search directions in google when isDelivery is false
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...memoValues, [event.target.name]: event.target.value })
    if (
      isDelivery &&
      event.target.name === 'search' &&
      event.target.value.length >= 2
    )
      setValue(event.target.value)
  }

  /*
   * @description OnClickClearInput is a function that takes a string and returns nothing.
   * @param {string} name - string - the name of the input field
   */
  const onClickClearInput = (name: string): void => {
    setValues({
      ...memoValues,
      [name]: ''
    })
  }

  /*
   * @description When the component mounts, set the responseAddress state to the addressList array.
   */
  const setInitialAddress = async () => {
    setResponseAddress(addressList)
  }

  useEvents({
    eventType: 'storeLocator',
    callBack: ({ detail: { show } }) => {
      setDirectionModal(show)
      setInitialAddress()
    }
  })

  /*
   * @description When the user clicks the button, the button status is set to initial and the direction modal is
   * toggled.
   * @returns The return value of the function is the return value of the trigger function.
   */
  const handleOnClick = () => {
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
    handleModal()
    return trigger({
      eventType: 'storeLocator',
      data: { show: !directionModal }
    })
  }

  /*
   * @description  It takes an object with three properties, and returns an array with two elements. The first element
   * is the result of an async function, and the second element is an object with a single property.
   * @param  - country -&gt; country code
   * @returns An array of two elements.
   */
  const handleCoverage = async ({ country, lng, lat }) => {
    const [data, { isCoverage }] = await directionCoverage({
      country,
      geoCoordinates: [lng, lat],
      items: productDummy,
      addressType: addressTypeToSend
    })
    return [data, { isCoverage }]
  }

  const handleSelectDeliveryAddress = async ({
    description
  }): Promise<void> => {
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'loading' }
    })
    const results = await getGeocode({ address: description })
    const formattedDirection = addressFormat(results)
    const {
      city: formattedCity,
      neighborhood,
      country
    } = formattedDirection || {}
    const { lat, lng } = await getLatLng(results[0])
    const coverage = await handleCoverage({
      country,
      lat,
      lng
    })
    const [data, { isCoverage }] = coverage
    dispatch({
      type: 'SAVE_LOCATION_FORMAT',
      payload: {
        lat: lat,
        lng: lng,
        municipality: formattedCity,
        commune: neighborhood,
        country: country
      }
    })
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
    if (!isCoverage) {
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'initial' }
      })
      setValues({})
      setDirectionModal(false)
      setFailModal(true)
      setAction(SHOW_ADDRESS_LIST)
      dispatch({
        type: 'SEND_MESSAGE_MODAL',
        payload: {
          alignTitle: 'center',
          buttonText: 'Cambiar dirección',
          colorTitle: getGlobalStyle('--color-base-black'),
          description:
            'No podemos despachar a tu dirección. Si quieres continuar comprando cambia tu dirección.',
          position: 'center',
          title: 'Dirección sin cobertura de despacho'
        }
      })
      setIdAddressCurrent(orderFormData.address ? true : false)
      setSaveWithOutCoverage(SAVE_ADDRESS_WITHOUT_COVERAGE)
    }
    if (data && isCoverage) {
      setMapModal(true)
      setDirectionModal(false)
      setAddress(description)
      setMapsAddress(formattedDirection)
      const numberAddress = formattedDirection?.number?.length === 0
      dispatch({
        type: 'ADDRESS_NUMBER_STREET',
        payload: { addressStreetNumber: numberAddress }
      })
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: numberAddress ? 'disabled' : 'initial' }
      })
      setValues({})
    }
  }

  const handleSelectPickUpAddress = ({ description }) => {
    const storeSelected = stores.find((store) => {
      return store.pickupPoint.address.geoCoordinates.join(';') === description
    })
    if (storeSelected) {
      setAddressSelected(storeSelected.pickupPoint.address)
      setFail({ failed: [] })
      setDirectionModal(false)
      setFailModal(true)
      setAction(CHANGE_ADDRESS)
      dispatch({
        type: 'SEND_MESSAGE_MODAL',
        payload: {
          alignTitle: 'center',
          buttonText: 'Cambiar dirección',
          colorTitle: getGlobalStyle('--color-base-black'),
          description:
            'Es posible que los productos que quieres comprar no se encuentren disponibles en la nueva ubicación.',
          position: 'center',
          title: '¿Estás seguro que quieres cambiar la dirección?'
        }
      })
    }
  }

  const handleSelect = async ({ description }): Promise<void> => {
    if (isDelivery) handleSelectDeliveryAddress({ description })
    if (!isDelivery) handleSelectPickUpAddress({ description })
  }

  /*
   *  @description If the user is in coverage, save the location, otherwise, show an error message.
   * @param {number} lat - -33.4488897
   * @param {number} lng - -70.664982
   */
  const setNewLocation = async (lat: number, lng: number) => {
    const { country } = state.geolocation
    const coverage = await handleCoverage({
      country,
      lat,
      lng
    })
    const { isCoverage } = coverage[1]
    if (!isCoverage) {
      coordsError(
        'Lo sentimos, no existe cobertura para esta dirección.',
        'message'
      )
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'disabled' }
      })
      cleanCoordsError()
      datalayerSimpleEvent({
        event: 'eventos_store_locator',
        eventAction: 'vista_modal_sin_cobertura_dir_manual',
        eventCategory: setPageLocation(),
        eventLabel: `Lat: ${lat}, Lng: ${lng}`
      })
    }
    if (isCoverage) {
      dispatch({
        type: 'SAVE_LOCATION_FORMAT',
        payload: {
          commune: state.geolocation.commune,
          country: state.geolocation.country,
          lat: lat,
          lng: lng,
          municipality: state.geolocation.municipality
        }
      })
      datalayerSimpleEvent({
        event: 'eventos_store_locator',
        eventAction: 'seleccion_direccion',
        eventCategory: setPageLocation(),
        eventLabel: 'sugerida'
      })
    }
  }

  /*
   * @description It's a function that dispatches an action to a reducer, and then sets the state of a component.
   */
  const errorModal = (): void => {
    dispatch({
      type: 'SEND_MESSAGE_MODAL',
      payload: {
        alignTitle: 'center',
        buttonText: 'Cambiar dirección',
        colorTitle: getGlobalStyle('--color-base-black'),
        description:
          'Lo sentimos, por el momento no podemos despachar a esta dirección. Prueba con una distinta.',
        position: 'center',
        title: 'Dirección sin cobertura de despacho'
      }
    })
    handleAddressListToDeliveryAndPickUp(
      orderFormData.address as unknown as CustomAddressSelectorProps
    )
    setFailModal(!failModal)
    handleOnClick()
    setAddress('')
    setValue('')
  }

  /*
   *  @description It sets the state of a modal to false, and then sets the state of a few other variables to false or
   * empty.
   * @param [withTag=false] - boolean
   */
  const mapCloseModal = (withTag = false): void => {
    withTag &&
      eventClick({
        eventCategory: 'interacciones en carro de compras',
        'event-label': 'modal cerrar'
      })
    setMapModal(!mapModal)
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
    setAddress('')
    setValues({})
    dispatch({
      type: 'ADDRESS_NUMBER_STREET',
      payload: { addressStreetNumber: false }
    })
    if (errorAddress) {
      setErrorAddress(!errorAddress)
    }
  }

  /*
   * @description It sets the state of the component to the initial state.
   */
  const back = (): void => {
    eventClick({
      eventCategory: 'interacciones en carro de compras',
      'event-label': 'modal atrás'
    })
    setAddress('')
    setValue('')
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
    setValues({})
    setMapModal(!mapModal)
    handleOnClick()
    dispatch({
      type: 'ADDRESS_NUMBER_STREET',
      payload: { addressStreetNumber: false }
    })
    if (errorAddress) {
      setErrorAddress(!errorAddress)
    }
  }

  /*
   * If the user's browser doesn't support geolocation, then display an error message.
   * @param {string} error - string, type: string
   * @param {string} type - string -&gt; this is the type of error, it can be either "coords" or
   * "address"
   */
  const coordsError = (error: string, type: string): void => {
    setErrorType(type)
    setErrorMapMessage(error)
    setErrorAddress(!errorAddress)
  }

  const cleanCoordsError = async (): Promise<void> => {
    await sleep(4000)
    setErrorType('')
    setErrorMapMessage('')
    setErrorAddress(false)
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
  }

  /*
   * It takes an object as an argument, and then it does some stuff with it
   * @param obj - Omit<NewAddressData, 'lat' | 'lng' | 'postalCode' | 'userId' | 'withSimulation'>
   * @returns a promise.
   */
  const handleNewAddress = async (
    obj: Omit<
      NewAddressData,
      'lat' | 'lng' | 'postalCode' | 'userId' | 'withSimulation'
    >
  ): Promise<void> => {
    if (state.buttonStatus === 'loading') {
      return
    }
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'loading' }
    })
    const { addressComments, addressRef, valueAddressStreetNumber } = memoValues
    const {
      city,
      country,
      neighborhood,
      number,
      state: town,
      street
    } = obj || {}
    const responsePostAddressData = await postNewAddress(obj as NewAddressData)
    const { status } = responsePostAddressData
    const { id, addressType } = responsePostAddressData.data
    const { lng, lat } = state.geolocation
    const SUCCESS = status === 201

    if (!SUCCESS) {
      coordsError(
        'Lo sentimos, tuvimos un error al cargar tu información por favor intenta de nuevo.',
        'error'
      )
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'initial' }
      })
    }

    if (SUCCESS) {
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'loading' }
      })
      handleAddressObject({
        addressId: id,
        addressType: addressType,
        city,
        complement: addressRef,
        country: country,
        geoCoordinates: [lng, lat],
        neighborhood: neighborhood,
        number: number.length === 0 ? valueAddressStreetNumber : number,
        reference: addressComments,
        salesChannel: '',
        site: 'alvi',
        state: town,
        street: street.length === 0 ? town : street
      })
      if (error)
        coordsError(
          'Lo sentimos, no existe cobertura para esta dirección.',
          'message'
        )
      cleanCoordsError()
      const newAddress = {
        ...obj,
        ...responsePostAddressData.data,
        selected: true,
        geoCoordinates: [lng, lat]
      }

      const newAddressList = [newAddress, ...addressList]
      setAddressList(newAddressList)
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'initial' }
      })
      setMapModal(!mapModal)
    }
  }

  /*
   * @description It's a function that sends a request to the server to create a new address, and then it changes the
   * state of the button to 'initial'
   * @returns the dispatch function.
   */
  const buttonAddressMap = async (): Promise<void> => {
    eventClick({
      eventCategory: 'interacciones en carro de compras',
      'event-label': 'modal botón continuar confirmación nueva dirección'
    })
    const { place_id, number, ...propsMapsAddress } = mapsAddress
    const { firstName, lastName } = orderFormData
    const { addressRef, valueAddressStreetNumber, addressComments } = memoValues
    const { geolocation } = state
    const { lng, lat } = geolocation
    if (typeDeliver === 'delivery') {
      cleanUrlParams()
    }
    return handleNewAddress({
      ...propsMapsAddress,
      complement: addressRef,
      geoCoordinate: [lng, lat],
      number: number.length === 0 ? valueAddressStreetNumber : number,
      placeId: place_id,
      receiverName: `${firstName} ${lastName}`,
      reference: addressComments
    })
  }

  const datalayerEvent = () => {
    datalayerSimpleEvent({
      event: 'eventos_store_locator',
      eventAction: 'vista_modal',
      eventCategory: setPageLocation(),
      eventLabel: isDelivery ? 'despacho a domicilio' : 'retiro en tienda'
    })
  }

  useEffect(() => {
    if (updateData) {
      if (updateData?.data?.error) {
        coordsError(
          'Lo sentimos, tuvimos un error al cargar tu información por favor intenta de nuevo.',
          'error'
        )
      } else {
        mapCloseModal()
      }
    } else if (updateData?.data?.error) {
      if (updateData?.data?.error) {
        dispatch({
          type: 'SET_STATUS_BUTTON',
          payload: { buttonStatus: 'disabled' }
        })
        throw new Error('Error en el cambio de dirección')
      } else {
        trigger({ eventType: 'storeLocator', data: { show: !directionModal } })
        dispatch({
          type: 'SET_STATUS_BUTTON',
          payload: { buttonStatus: 'disabled' }
        })
      }
    }
  }, [updateData?.data?.error])

  /*
   * @description If orderFormData has an address property, return the addressId property of the address property of
   * orderFormData, otherwise return null.
   * @returns The addressId of the userAddress object.
   */
  const getAddressId = (): string | null => {
    if (orderFormData?.address) {
      const { address: userAddress } = orderFormData
      const { addressId } = userAddress
      return addressId
    }
    return null
  }

  /*
   * If the user has an address, set the address list to the user's address and the rest of the
   * addresses, otherwise set the address list to the rest of the addresses.
   * @param response - [{id: "1", addressName: "Home", selected: false}, {id: "2", addressName: "Work",
   * selected: false}]
   */
  const setResponseAddress = (response) => {
    if (addressType !== 'residential' || !isDelivery) return null
    const userAddress = getAddressId()
    setIdAddressCurrent(userAddress === '' ? false : true)
    const addressListFormatted =
      response
        .map((address) => {
          return {
            ...address,
            selected: false
          }
        })
        .filter((address) => {
          return address.addressName !== userAddress
        }) || []
    const addressListFilter =
      addressListFormatted.filter((x) => {
        return x.id !== userAddress
      }) || []
    const selectedAddresses = orderFormData?.address
    const currentAddress = typeof orderFormData?.address === 'undefined'
    const userAddressCurrent = {
      id: userAddress,
      selected: true,
      geoCoordinates: selectedAddresses?.geoCoordinates,
      ...selectedAddresses
    }
    const formattedAddresses = [userAddressCurrent, ...addressListFilter]
    if (currentAddress) {
      setAddressSelected(userAddressCurrent)
      const onlyOneAddress = response.map((x) => {
        return { ...x, selected: true }
      })
      return setAddressList(onlyOneAddress)
    }
    if (selectedAddresses) {
      setAddressSelected(userAddressCurrent)
      return setAddressList(formattedAddresses)
    }
    return setLoadingAddress(false)
  }

  useEffect(() => {
    ;(async function () {
      const existAddressInOrderForm =
        typeof orderFormData?.address === 'undefined' && !loadingOrderForm
      if (
        ['store', 'delivery'].includes(typeDeliver) ||
        existAddressInOrderForm
      ) {
        setIsDelivery(typeDeliver === 'delivery')
      }
      setLoadingAddress(true)
      setValue('')
      const response = await getAddressAlvi()
      setLoadingAddress(false)
      const responseAddress = response?.length > 0
      if (responseAddress) {
        const initialAddress = response.map((initAddress) => {
          const selected =
            orderFormData?.address?.addressId === initAddress.addressName
          return { ...initAddress, selected }
        })
        dispatch({
          type: 'SET_STATUS_BUTTON',
          payload: { buttonStatus: 'disabled' }
        })
        return setAddressList(initialAddress)
      }
      if (orderFormData?.address && responseAddress) {
        return setResponseAddress(response)
      }
      return setLoadingAddress(false)
    })()
    if (!typeDeliver) setIsDelivery(isDeliveryValidation)
  }, [orderFormData?.address, isFetched])

  /*
   * @description A callback function that is being used to set the state of the addressList.
   * @param {UserAddressListProps} item
   * @param {number|undefined} index
   * @returns {AddressListPropsAlvi[]}
   */

  const addressSelector = async (item: CustomAddressSelectorProps) => {
    const addressSelected = {
      geoCoordinates: item.geoCoordinate,
      selected: true,
      ...item
    }

    setAddressSelected(addressSelected)
    handleAddressListToDeliveryAndPickUp(item)
    await confirmUserAddress(addressSelected)
  }

  const handleAddressListToDeliveryAndPickUp = (
    item: CustomAddressSelectorProps
  ) => {
    const newAddressList =
      addressList.length > 0
        ? addressList.map((address) => {
            const selected = address.id === item.id && isDelivery
            return { ...address, selected }
          })
        : []

    const newStoreList =
      storeList.length > 0
        ? storeList.map((address) => {
            const selected =
              address.pickupPoint.address.addressId === item.addressId &&
              !isDelivery
            address.pickupPoint.address.selected = selected
            return address
          })
        : []

    setStoreList(newStoreList)
    setAddressList(newAddressList)
    setAddressSelected({ ...item, selected: true })
  }
  /*
   * If the addressSelected variable is true, then create a new object called currentAddress, and then
   * call the handleAddressObject function, and then if the isLoading variable is true, then set the
   * buttonStatus variable to 'loading', and then create a new array called finalAddressList, and then
   * create a new array called newCurrentAddress, and then set the addressList variable to
   * newCurrentAddress, and then set the directionModal variable to false.
   */
  const saveAddress = async (): Promise<void> => {
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'loading' }
    })
    if (addressSelected) {
      const currentAddress = {
        ...addressSelected,
        addressId: addressSelected?.addressName || addressSelected?.addressId,
        geoCoordinates:
          addressSelected?.geoCoordinates || addressSelected?.geoCoordinate,
        id: addressSelected?.id,
        selected: true,
        site
      }
      datalayerSimpleEvent({
        event: 'eventos_store_locator',
        eventAction: 'cambiar_metodo_entrega',
        eventCategory: setPageLocation(),
        eventLabel:
          currentAddress?.addressType === 'pickup'
            ? 'Retiro en tienda'
            : 'Despacho a domicilio'
      })
      handleAddressObject({
        ...currentAddress,
        site,
        logisticsInfo: []
      })
      if (isLoading) {
        dispatch({
          type: 'SET_STATUS_BUTTON',
          payload: { buttonStatus: 'loading' }
        })
      }
      const finalAddressList = addressList.filter((i) => {
        return i.id !== addressSelected.id
      })
      const newCurrentAddress = [currentAddress, ...finalAddressList]
      setAddressList(newCurrentAddress)
      setDirectionModal(false)
    }
    if (typeDeliver === 'delivery') {
      cleanUrlParams()
    }
  }

  /*
   * @description If the user's address is not covered by the delivery service, then show a modal with a message and a
   * button to change the address.
   * @returns {any}
   */
  const setDirectionModalCoverage = () => {
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
    setFailModal(true)
    setDirectionModal(false)
    setErrorFailMobile(false)
    setAction(SHOW_ADDRESS_LIST)
    setSaveWithOutCoverage(SAVE_ADDRESS_WITHOUT_COVERAGE)
    dispatch({
      type: 'SEND_MESSAGE_MODAL',
      payload: {
        alignTitle: 'center',
        buttonText: 'Cambiar dirección',
        colorTitle: getGlobalStyle('--color-base-black'),
        description:
          'No podemos despachar a tu dirección. Si quieres continuar comprando cambia tu dirección.',
        position: 'center',
        title: 'Dirección sin cobertura de despacho'
      }
    })
  }

  /*
   * "If the user is searching for an address, return the button to its initial state. Otherwise, set the
   * button to loading, get the country and geoCoordinates of the selected address, and simulate the cart
   * with the country, geoCoordinates, and items. If the cart simulation fails, set the fail modal to
   * true, set the action to CHANGE_ADDRESS, and send a message to the modal. If the cart simulation
   * succeeds, save the address. Finally, set the button to its initial state."
   *
   * I'm not sure if this is the best way to write this function, but it's a start.
   * @returns return dispatch({ type: 'SET_STATUS_BUTTON', payload: { buttonStatus: 'initial' } })
   */
  const confirmUserAddress = async ({
    country,
    geoCoordinates
  }: CustomAddressSelectorProps) => {
    if (!geoCoordinates) return false

    try {
      if (searchingAddress && isDelivery) {
        return dispatch({
          type: 'SET_STATUS_BUTTON',
          payload: { buttonStatus: 'initial' }
        })
      }
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'loading' }
      })
      // STATES
      const coverage = await simulateCart({
        country,
        geoCoordinates,
        items: productDummy,
        addressType: addressTypeToSend
      })
      const isCoverage = Boolean(coverage?.nextWindow)
      const userAddress = getAddressId()
      // HANDLES
      if (!isCoverage && isDelivery) return setDirectionModalCoverage()
      if (!userAddress) return await saveAddress()
      if (isCoverage || !isDelivery) {
        setFail({
          failed: coverage.failed
        })
        setDirectionModal(false)
        setFailModal(true)
        setAction(CHANGE_ADDRESS)
        dispatch({
          type: 'SEND_MESSAGE_MODAL',
          payload: {
            alignTitle: 'center',
            buttonText: 'Cambiar dirección',
            colorTitle: getGlobalStyle('--color-base-black'),
            description:
              'Es posible que los productos que quieres comprar no se encuentren disponibles en la nueva ubicación.',
            position: 'center',
            title: '¿Estás seguro que quieres cambiar la dirección?'
          }
        })
      }
      cleanUrlParams()
      return dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'initial' }
      })
    } catch (error) {
      return dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'initial' }
      })
    } finally {
      setIsDelivery(addressType === 'residential' ? true : false)
    }
  }

  /**
   * @description When the user clicks the button, open the modal, set the error to true, set the direction to false,
   * and dispatch the action to set the button status to initial.
   */
  const handleActionModalOutStock = () => {
    setOpenModalOutStock(true)
    setErrorFailMobile(true)
    setDirectionModal(false)
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
  }

  /*
   * @description When the user clicks the button, the button's status is set to 'initial' and the function
   * handleOnClick is called.
   * @returns {any}
   */
  const handleActionShowAddressList = () => {
    handleOnClick()
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
  }

  const validateMessageError = (): boolean => {
    const arrayFilter = arrFail?.failed?.filter(({ message }) => {
      return message === 'El item store Locator ya no se encuentra disponible'
    })

    return Boolean(arrayFilter.length)
  }

  /*
   * @description It's a function that returns a function that returns a function.
   * @returns The return value of the function is the return value of the last function call.
   */
  const action = () => {
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'loading' }
    })
    const newStoreList = storeList.map((store) => {
      store.pickupPoint.address.selected = false
      return store
    })
    setStoreList(newStoreList)
    if (active === CHANGE_ADDRESS) {
      if (arrFail?.failed?.length > 0 && !validateMessageError()) {
        return handleActionModalOutStock()
      }
      return saveAddress()
    }
    if (active === SHOW_ADDRESS_LIST) {
      return handleActionShowAddressList()
    }
    return () => {
      setValue('')
      setAddress('')
      setValues({})
    }
  }

  /*
   * @description If the app is loading, return. Otherwise, set the direction modal to false, set the fail modal to
   * false, set the error fail mobile to false, and dispatch an action to set the button status to
   * initial.
   * @returns The return is a function that is being called.
   */
  const handleKeepAddress = (): void => {
    if (isLoading) return

    resetAddressAndStore()
    setDirectionModal(false)
    setFailModal(false)
    setErrorFailMobile(false)
    dispatch({
      type: 'SET_STATUS_BUTTON',
      payload: { buttonStatus: 'initial' }
    })
  }

  const handleChangeIsDelivery = () => {
    setIsDelivery(!isDelivery)
  }

  /*
   * @description Mapping the data from the API call and returning an object with the data.
   * @returns {data}
   */
  const handleAddressListModalContent = (): AddressListPropsAlvi[] => {
    if (isDelivery && searchingAddress) {
      return data.map((addressData, index) => {
        const { description } = addressData || {}
        const { main_text, secondary_text } =
          addressData.structured_formatting || {}
        const activeIndex = index === state.selectedIndex
        return {
          activeIndex: activeIndex,
          activeMap: false,
          city: secondary_text,
          description,
          failModal: false,
          name: main_text
        }
      })
    }

    if (!isDelivery) {
      const regexSearch = new RegExp(`${memoValues.search}`, 'i')
      let storesToList = []
      if (memoValues.search && memoValues.search.length > 2) {
        storesToList = stores
          .filter((store) => {
            const friendlyName = regexSearch.test(
              store.pickupPoint.friendlyName
            )
            const street = regexSearch.test(store.pickupPoint.address.street)
            const city = regexSearch.test(store.pickupPoint.address.city)
            const neighborhood = regexSearch.test(
              store.pickupPoint.address.neighborhood
            )
            return street || city || neighborhood || friendlyName
          })
          .map((store) => {
            const address = store.pickupPoint.address
            const name = `Alvi ${address.neighborhood}`
            const city = `${address.street}, ${address.number}, ${address.state}`
            return {
              activeIndex: false,
              activeMap: false,
              city,
              description: address.geoCoordinates.join(';'),
              failModal: false,
              name
            }
          })
      }
      return storesToList
    }

    return []
  }

  /**
   * @description When the user clicks on the map icon, the map modal will open and the direction modal and fail modal
   * will close.
   */
  const handleShowModal = () => {
    setMapModal(false)
    setDirectionModal(false)
    setFailModal(false)
  }

  const saveAddressWithOutCoverage = saveWithoutCoverage
    ? handleShowModal
    : handleKeepAddress

  const failProps = {
    arrFail,
    buttonStatus,
    buttonText: state.message.buttonText,
    colorTitle: state.message.colorTitle,
    content: state.message.description,
    contentPosition: state.message.position as TitleProps['textAlign'],
    isOpenModalOutStock,
    showButton: idAddressCurrent,
    site: site as Site,
    title: state.message.title,
    titlePosition: state.message.alignTitle as TitleProps['textAlign'],
    action,
    saveAddress,
    handleSaveAddressWithOutCoverage: saveAddress,
    handleKeepAddress: setWithoutCoverage
      ? saveAddress
      : saveAddressWithOutCoverage,
    handleModal,
    onClick: errorModal
  }

  const MapProps = {
    buttonStatus,
    city: state.geolocation.municipality,
    commune: state.geolocation.commune,
    errorAddress,
    errorAddressType: errorType,
    errorMapMessage,
    screenHeight,
    selectedAddress: address,
    site: site as Site,
    streetNumberInput: state.addressStreetNumber,
    titleModal: 'Información de la dirección',
    values: memoValues,
    back,
    buttonAddressMap,
    handleChange,
    handleModal,
    onClick: mapCloseModal,
    onClickClearInput
  }

  const AddressProps = {
    addressList: handleAddressListModalContent(),
    isDelivery,
    loadingAddress,
    placeholderSearch: 'Ingresa una nueva dirección...',
    screenHeight,
    showSubtitleList,
    site: site as Site,
    sla,
    storeList,
    titleModal: 'Elige tu método de entrega',
    userAddressList: addressList,
    value: address,
    values: memoValues,
    addressSelector,
    dispatch,
    handleChange,
    handleChangeIsDelivery,
    handleModal,
    onClick: handleOnClick,
    onClickClearInput,
    onSelect: handleSelect,
    datalayerEvent: datalayerEvent
  }

  const propsStorLocatorAlvi: StoreLocatorPropsAlvi = {
    AddressProps,
    arrFail,
    directionModal,
    errorMobileModal,
    failModal,
    failModalTitle: state.message.title,
    failProps,
    isMobile,
    mapModal,
    MapProps
  }

  const propsMapModal = {
    addressTypeToSend,
    errorAddress,
    height: 290,
    location: { lngMap: state.geolocation.lng, latMap: state.geolocation.lat },
    productDummy,
    cleanCoordsError,
    coordsError,
    setNewLocation
  }

  const deliverySelected =
    propsStorLocatorAlvi.AddressProps.userAddressList.filter((item) => {
      return item.selected === true
    })
  const pickUpSelected = propsStorLocatorAlvi.AddressProps.storeList.filter(
    (store) => {
      return store.pickupPoint.address.selected === true
    }
  )

  // EFFECTS
  useEffect(() => {
    if (directionModal || mapModal || failModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    if (isLoading) {
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'loading' }
      })
    }
  }, [directionModal, mapModal, failModal, isLoading])

  useEffect(() => {
    const arrayListAddress = addressList?.length > 0
    if (!arrayListAddress) return
    const checkStatusAddress =
      addressList &&
      addressList.findIndex((a) => {
        return a.selected === true
      })
    const findOneSelected = checkStatusAddress === -1
    if (!findOneSelected) {
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'initial' }
      })
    }
    if (findOneSelected) {
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'disabled' }
      })
    }
  }, [addressList, addressSelected])

  useEffect(() => {
    if (mapModal && memoValues.valueAddressStreetNumber?.length > 0) {
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'initial' }
      })
    }
    if (mapModal && memoValues.valueAddressStreetNumber?.length <= 0) {
      dispatch({
        type: 'SET_STATUS_BUTTON',
        payload: { buttonStatus: 'disabled' }
      })
    }
  }, [memoValues?.valueAddressStreetNumber])

  useEffect(() => {
    if (!searchingAddress) return
    if (arrowUpPressed) {
      dispatch({ type: 'ARROW_UP', payload: { data: data } })
    }
    if (arrowDownPressed) {
      dispatch({ type: 'ARROW_DOWN', payload: { data: data } })
    }
  }, [arrowUpPressed, arrowDownPressed])

  useEffect(() => {
    const chooseStore = async () => {
      if (avliStores.isLoading) return
      const newStores = stores.map((store) => {
        store.pickupPoint.address.selected =
          geocoordinates === store.pickupPoint.address.geoCoordinates.join(';')
        return store
      })
      setStoreList(newStores)
    }
    chooseStore()
  }, [avliStores.isLoading])

  useEffect(() => {
    const validateSla = async () => {
      const response = await simulateCart({
        country: selectedAddresses?.['country'],
        geoCoordinates: selectedAddresses?.['geoCoordinates'],
        items: [
          {
            quantity: '1',
            seller: '1',
            id: process.env.NEXT_PUBLIC_PRODUCT_DUMMY || ''
          }
        ],
        addressType: addressTypeToSend
      })
      const slaValue = response?.slas?.find((logisticsInfo) => {
        return logisticsInfo.availableDeliveryWindows.length > 0
      })?.availableDeliveryWindows[0]
      if (orderFormData?.address && !slaValue) setSla('Sin cobertura')
      if (response?.nextWindow) {
        const { lisPrice, startDateUtc } = response.nextWindow
        const { day, month, hourMinutes24, shortDayName } = formatDate({
          date: startDateUtc
        })
        const slaPrice = `${currencyFormat({ number: lisPrice })}`
        setSla(
          `Desde: ${
            shortDayName.charAt(0).toUpperCase() + shortDayName.slice(1)
          } ${day}/${month}, ${hourMinutes24} hrs - ${slaPrice}`
        )
      }
    }
    if (!isLoading) {
      validateSla()
    }
  }, [orderFormData?.address, isLoading])

  useEffect(() => {
    const resizeEvent = () => {
      const customSizeByEachDevice = `${(
        window.innerHeight * 0.9
      ).toString()}px`
      setScreenHeight(customSizeByEachDevice)
    }
    window.addEventListener('resize', resizeEvent)
    return () => {
      window.removeEventListener('resize', resizeEvent)
    }
  }, [])

  useEffect(() => {
    if (
      propsStorLocatorAlvi.directionModal === true &&
      !propsStorLocatorAlvi.AddressProps.loadingAddress
    ) {
      if (!isDelivery && pickUpSelected[0]?.pickupPoint?.address?.selected) {
        datalayerSimpleEvent({
          event: 'eventos_store_locator',
          eventAction: 'seleccion_retiro_tienda',
          eventCategory: setPageLocation(),
          eventLabel: `Alvi ${pickUpSelected[0]?.pickupPoint.address.neighborhood}`
        })
      }
      if (isDelivery && deliverySelected[0]?.selected) {
        const { state, neighborhood } = deliverySelected[0]
        datalayerSimpleEvent({
          event: 'eventos_store_locator',
          eventAction: 'seleccion_despacho_domicilio',
          eventCategory: setPageLocation(),
          eventLabel: `Alvi ${neighborhood}`
        })
        datalayerSimpleEvent({
          event: 'eventos_store_locator',
          eventAction: 'region_seleccionada',
          eventCategory: setPageLocation(),
          eventLabel: state?.toLocaleLowerCase()
        })
        datalayerSimpleEvent({
          event: 'eventos_store_locator',
          eventAction: 'seleccion_comuna',
          eventCategory: setPageLocation(),
          eventLabel: neighborhood
        })
      }
    }
  }, [
    propsStorLocatorAlvi.directionModal,
    deliverySelected[0],
    pickUpSelected[0]
  ])

  useEffect(() => {
    if (
      propsStorLocatorAlvi.failModal === true &&
      propsStorLocatorAlvi.failModalTitle ===
        'Dirección sin cobertura de despacho'
    ) {
      datalayerSimpleEvent({
        event: 'store_locator_cambiar',
        eventAction: 'vista_modal_sin_cobertura',
        eventCatgory: setPageLocation(),
        eventLabel: pickUpSelected[0]
          ? `Alvi ${pickUpSelected[0]?.pickupPoint?.address?.neighborhood}`
          : `Alvi ${deliverySelected[0]?.neighborhood}`
      })
    }

    if (
      propsStorLocatorAlvi.failModal === true &&
      propsStorLocatorAlvi.failModalTitle ===
        '¿Estás seguro que quieres cambiar la dirección?'
    ) {
      datalayerSimpleEvent({
        event: 'store_locator_cambiar',
        eventAction: 'cambiar dirección',
        eventCatgory: setPageLocation(),
        eventLabel: pickUpSelected[0]
          ? `Alvi ${pickUpSelected[0]?.pickupPoint.address.neighborhood}`
          : `Alvi ${deliverySelected[0].neighborhood}`
      })
    }
  }, [propsStorLocatorAlvi.failModal, propsStorLocatorAlvi.failModalTitle])

  useEffect(() => {
    if (mapModal === true) {
      datalayerSimpleEvent({
        event: 'eventos_store_locator',
        eventCategory: setPageLocation(),
        eventAction: 'seleccion_direccion',
        eventLabel: 'sugerida'
      })
    }
  }, [mapModal])

  return (
    <>
      {validateStoreLocatorAvailableRoute(router) && (
        <StoreLocatorAlvi {...propsStorLocatorAlvi}>
          <Map {...propsMapModal} />
        </StoreLocatorAlvi>
      )}
    </>
  )
}
