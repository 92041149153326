import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Container,
  createNotification,
  Header,
  Overlay,
  SearchProps
} from '@smu-chile/pkg-unimarc-components'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { StoreLocatorHeaderProps } from '@smu-chile/pkg-unimarc-components/stories/organisms/Header'
import {
  AddressTypeProp,
  capitalize,
  checkUserLoginFlow,
  Cookies,
  currencyFormat,
  eventClick,
  getContentfulImageCategories,
  postAlviLogout,
  simulateCart,
  sleep,
  tagClickCouponIconInHeader,
  useAlviSession,
  useCart,
  useCategories,
  useContentful,
  useEvents,
  useMobile,
  useQuery,
  useSearch,
  useTrigerEvent,
  useUpdateFromShelfAlvi,
  useUserSearch
} from '@smu-chile/pkg-unimarc-hooks'
import { ForgotPasswordModalBrowse } from 'components/ForgotPasswordModalBrowse'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { formatDate } from 'shared/utils/formatDate'
import { aislesProps, IResult, searchProps } from './helpers'
import {
  useImagesDropDownMenu,
  variableBreakdownCF
} from './helpers/variableBreakdownCF'
import { myProfileBannerAssetIdByShabitName } from './helpers/myProfileDropdown'
import styles from './HeaderBrowse.module.css'
import { DropDownMyProfile } from '@smu-chile/pkg-unimarc-components/stories/organisms/Header/MyProfile/DropDownMyProfile'
import { getContentfulLoginBanner } from 'shared/contentful/login'
import { useAislesVisible } from './helpers/useAislesVisible'
import {
  getContentfulEmojiCelebration,
  getContentfulEmojiLocation,
  getContentfulEmojiStoreDelivery,
  getContentfulEmojiStorePickup
} from 'shared/contentful/storeLocator'
import { couponProps } from './helpers/couponProps'
import { useMyFirstAddress } from './helpers/useMyFirstAddress'
import { IUserSearchResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IUserSearchSchema'
import { useOfferLink } from 'shared/hooks/useOfferLink'

const customProperties = (user: IUserSearchResponse, isMobile: boolean) => {
  const validationStatus: string = user?.validationStatus ?? 'logout'
  const positionHeaderBrowse: typeof Container.arguments.position = isMobile
    ? 'sticky'
    : 'initial'

  return { validationStatus, positionHeaderBrowse }
}

export const HeaderBrowse = (): React.ReactElement => {
  // use
  const { isLoggedIn } = useAlviSession()
  const { data: user, isFetched: isUserFetched } = useUserSearch()
  const userId = user?.userId
  const router = useRouter()
  const offerLink = useOfferLink()

  const { trigger } = useTrigerEvent()
  const { isMobile } = useMobile()
  const { result, handleChangeSearch } = useSearch('alvi')
  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE,
      'sys.id[in]': '4TMBhOiEMQGcWgesH1ASsa'
    }
  })
  const { data, isFetched, isLoading } = useCart()

  const addressType = data?.address?.addressType as AddressTypeProp
  const resultAisles = useCategories({
    site: 'alvi',
    reactQuery: { queryKey: 'header' }
  })
  const inputAdornmentRef = useRef(null)
  // is
  const [name, setName] = useState('Mi perfil')
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const [isAccountTooltipOpen, setIsAccountTooltipOpen] = useState(true)
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [isVisableBanner, setIsVisableBanner] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isVisibleAisles, setIsVisibleAisles] = useState(false)
  const [isVisibleClearIcon, setIsVisibleClearIcon] = useState(false)
  const [isVisibleModalLogout, setIsVisibleModalLogout] = useState(false)
  const [isVisibleMyProfile, setIsVisibleMyProfile] = useState(false)
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [redirectTo, setRedirectTo] = useState('')
  const [sla, setSla] = useState('')
  const [slaPrice, setSlaPrice] = useState('')
  const [value, setValue] = useState('')

  // primitive
  const site = 'alvi'
  const shouldLoginModalBeOpen = router.query.login === 'true'
  const shouldForgotPasswordModalBeOpen =
    router.query['forgot-password'] === 'true'
  const selectedAddresses = data?.address
  const isPickup = selectedAddresses?.addressType == 'pickup-in-point'
  const addressDelivery = selectedAddresses?.street
    ? `${selectedAddresses?.street}, ${selectedAddresses?.number}`
    : ''
  const addressSelected = isPickup
    ? `Alvi ${selectedAddresses?.neighborhood}` || ''
    : addressDelivery
  const iconStoreLocatorLogin = isPickup ? 'Shop' : 'Location'
  const iconStoreLocator: StoreLocatorHeaderProps['iconStoreLocator'] =
    isLoggedIn && selectedAddresses ? iconStoreLocatorLogin : 'Location'
  const showCouponTooltip =
    process.env.NEXT_PUBLIC_SHOW_COUPON_TOOLTIP === 'true'

  const { validationStatus } = customProperties(user, isMobile)
  const { cfDataHeader, handleClicks } = variableBreakdownCF(
    user?.level || 'clubalvi',
    router,
    setIsVisibleMyProfile
  )
  const dropDownMenuImage = useImagesDropDownMenu(user?.level, router)

  // get
  const categoriesDataImage = getContentfulImageCategories(categoriesApp?.data)

  const { editTempOrderForm, ...useUpdateProps } = useUpdateFromShelfAlvi({
    fromWhere: 'search',
    isOrderFormLoading,
    isSearch: true,
    products: result?.intelligenceSearch?.data?.pages[0]?.availableProducts,
    userId
  })
  const smallScreenSLVisible =
    typeof window !== 'undefined' &&
    !['/live-shopping-unimarc'].includes(window.location.pathname)

  const searchPropsData = searchProps({
    ...useUpdateProps,
    inputAdornmentRef,
    isMobile,
    isVisible,
    isVisibleClearIcon,
    result: result as unknown as IResult,
    userId,
    value,
    handleChangeSearch,
    setIsVisible,
    setIsVisibleClearIcon,
    setValue
  })
  const aislesPropsData = aislesProps({
    categoriesDataImage,
    isMobile,
    isVisableBanner,
    isVisibleAisles,
    offersMobile: offerLink.offerLink,
    resultAisles,
    setIsVisableBanner,
    setIsVisibleAisles
  })

  const getAccountTooltipOpen = () => {
    const isFromContentRegister = router.query['fromContent'] === 'register'
    return isAccountTooltipOpen && isFromContentRegister && isLoggedIn
  }

  const redirect = (url: string) => {
    window.location.href = url
  }

  const handleClose = () => {
    if (isVisible) setIsVisible(false)
    if (isVisibleAisles) setIsVisibleAisles(!isVisibleAisles)
    if (isVisibleMyProfile) setIsVisibleMyProfile(false)
  }
  useEffect(() => {
    if (isVisibleAisles)
      window.addEventListener('keyup', (e) => {
        return e.code === 'Escape' && setIsVisibleAisles(false)
      })
    return () => {
      return window.removeEventListener('keyup', () => {
        return {}
      })
    }
  }, [isVisibleAisles])

  const handleOpenLoginModal = () => {
    setLoginModalOpen(true)
    router.push(
      {
        query: {
          ...router.query,
          login: true,
          ['forgot-password']: false,
          step: undefined
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const handleCloseLoginModal = async () => {
    setLoginModalOpen(false)
    trigger({ eventType: 'storeLocator', data: { show: false } })
    await router.push(
      {
        query: {
          ...router.query,
          login: undefined,
          ['forgot-password']: false,
          step: undefined
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const handleOpenForgotPasswordModal = () => {
    setForgotPasswordModalOpen(true)
    setLoginModalOpen(false)
    router.push(
      {
        query: {
          ...router.query,
          login: undefined,
          ['forgot-password']: true
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const handleCloseForgotPasswordModal = () => {
    setForgotPasswordModalOpen(false)
    router.push(
      {
        query: {
          ...router.query,
          login: undefined,
          ['forgot-password']: undefined,
          ['step']: undefined
        }
      },
      undefined,
      {
        shallow: true
      }
    )
  }

  const handleLogout = async () => {
    Cookies.remove('addToCart')
    const res = await postAlviLogout()
    if (res.status === 200) {
      window.location.reload()
    } else {
      createNotification({
        message: 'Error al cerrar sesión, por favor intente nuevamente',
        align: 'top-right',
        site: 'alvi',
        closeTimeout: 2000
      })
    }
  }

  const handleStoreLocator = () => {
    if (!isLoggedIn) {
      handleOpenLoginModal()
      return
    }
    eventClick({
      eventCategory: 'interacciones en carro de compras',
      'event-label': 'selector de direccion en header'
    })
    trigger({ eventType: 'storeLocator', data: { show: true } })
  }

  // validate if should redirect when finsih loading orderForm
  useEffect(() => {
    if (
      !isOrderFormLoading &&
      redirectTo != '' &&
      useUpdateProps?.saveDataState.length == 0
    ) {
      redirect(redirectTo)
    }
  }, [isOrderFormLoading, redirectTo, useUpdateProps?.saveDataState])

  // validate if should redirect when finsih loading orderForm
  useEffect(() => {
    if (
      !isOrderFormLoading &&
      redirectTo != '' &&
      useUpdateProps?.saveDataState.length == 0
    ) {
      redirect(redirectTo)
    }
  }, [isOrderFormLoading, redirectTo, useUpdateProps?.saveDataState])

  const handleClickCart = () => {
    const validation = checkUserLoginFlow(isLoggedIn, data?.address, true, site)
    const hasQuantity = useUpdateProps?.saveDataState.length == 0

    if (validation && hasQuantity && !isOrderFormLoading)
      router.push(process.env.NEXT_PUBLIC_CARTURL)
    if (!hasQuantity) {
      setRedirectTo(process.env.NEXT_PUBLIC_CARTURL)
      trigger({
        eventType: 'addToCart',
        data: {
          loading: true,
          items: useUpdateProps?.saveDataState
        }
      })
    }
  }

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      editTempOrderForm(items, loading)
      setIsOrderFormLoading(loading)
    }
  })

  useEvents({
    eventType: 'loginModal',
    callBack: ({ detail: { show } }) => {
      if (show) handleOpenLoginModal()
      else handleCloseLoginModal()
    }
  })

  useEvents({
    eventType: 'forgotPassword',
    callBack: ({ detail: { show } }) => {
      if (show) handleOpenForgotPasswordModal()
      else handleCloseForgotPasswordModal()
    }
  })

  // validate if should redirect when finsih loading orderForm
  useEffect(() => {
    if (
      !isOrderFormLoading &&
      redirectTo != '' &&
      useUpdateProps?.saveDataState.length == 0
    ) {
      router.push(redirectTo)
    }
  }, [isOrderFormLoading, redirectTo, useUpdateProps?.saveDataState])

  useEffect(() => {
    setLoginModalOpen(shouldLoginModalBeOpen)
  }, [shouldLoginModalBeOpen])

  useEffect(() => {
    setForgotPasswordModalOpen(shouldForgotPasswordModalBeOpen)
  }, [shouldForgotPasswordModalBeOpen])

  const myFirstAddress = useMyFirstAddress()

  useEffect(() => {
    ;(async () => {
      const existAddressInOrderForm =
        typeof data?.address === 'undefined' && !isLoading
      const notExistAddress =
        !selectedAddresses || selectedAddresses?.['country'] == ''
      if (
        isUserFetched &&
        notExistAddress &&
        isFetched &&
        existAddressInOrderForm
      ) {
        await sleep(1000)
        myFirstAddress.showMyFirstAddress()
        setSla('')
      }
      const response = await simulateCart({
        country: selectedAddresses?.['country'],
        geoCoordinates: selectedAddresses?.['geoCoordinates'],
        items: [
          {
            quantity: '1',
            seller: '1',
            id: process.env.NEXT_PUBLIC_PRODUCT_DUMMY || ''
          }
        ],
        addressType
      })
      const slaValue = response?.slas?.find((logisticsInfo) => {
        return logisticsInfo.availableDeliveryWindows.length > 0
      })?.availableDeliveryWindows[0]
      if (data?.address && !slaValue) setSla('Sin cobertura')
      if (response?.nextWindow) {
        const { lisPrice, startDateUtc } = response.nextWindow
        const { day, hourMinutes24, shortDayName, shortMonthName } = formatDate(
          { date: startDateUtc }
        )
        const slaPrice = isPickup
          ? 'Gratis'
          : `${currencyFormat({ number: lisPrice })}`
        const shortMonthNameCapital = capitalize(shortMonthName)
          .split('.')
          .join('')
        setSla(
          `${
            shortDayName.charAt(0).toUpperCase() + shortDayName.slice(1)
          } ${day} ${shortMonthNameCapital}, ${hourMinutes24} hrs`
        )
        setSlaPrice(slaPrice)
      }
    })()
  }, [selectedAddresses, isFetched, isUserFetched, isLoading])
  useEffect(() => {
    if ((!isLoading && user?.firstName) || data?.firstName) {
      const userNickName = user?.firstName
      const dataNickName = data?.firstName
      if (!userNickName && !dataNickName) {
        return setName('Mi perfil')
      }
      if (userNickName && !dataNickName) {
        return setName(dataNickName)
      }
      if (!userNickName && dataNickName) {
        return setName(userNickName)
      }
      if (userNickName && dataNickName) {
        return setName(dataNickName)
      }
      return setName('Mi perfil')
    }
    return setName('Mi perfil')
  }, [isLoading, isFetched, user?.firstName, data?.firstName])

  const clubLoginBanner = useQuery('clubLoginBanner', () => {
    return getContentfulLoginBanner()
  })

  const loginImageUrl = clubLoginBanner.data?.fields?.file?.url

  const myProfileBannerImageData = useContentful({
    id_contentful: 'my_profile_banner',
    addOptionsToCacheKey: true,
    options: {
      'sys.id': myProfileBannerAssetIdByShabitName[user?.level]
    },
    type: 'assets'
  })

  const myProfileBannerImageUrl =
    myProfileBannerImageData.data?.['items'].length === 1
      ? myProfileBannerImageData.data?.['items']?.[0].fields.file.url
      : null

  const handleAccountTooltipClose = () => {
    setIsAccountTooltipOpen(false)
  }

  const handleClickClub = () => {
    tagClickCouponIconInHeader()
    router.push(process.env.NEXT_PUBLIC_CLUBURL)
  }

  const handleClickGoToMyCoupons = async () => {
    await router.push(process.env.NEXT_PUBLIC_COUPONSURL)
    setIsVisibleMyProfile(false)
  }

  const handleClickMyProfile = () => {
    if (isMobile) {
      router.push(process.env.NEXT_PUBLIC_MYORDERSURL)
    } else {
      setIsVisibleAisles(false)
      setIsVisibleMyProfile(!isVisibleMyProfile)
    }
  }

  const shouldAccountTooltipBeOpen = getAccountTooltipOpen()

  useAislesVisible({ isMobile, setIsVisibleAisles })

  const emojiCelebration = useQuery('emojiCelebration', () => {
    return getContentfulEmojiCelebration()
  })

  const emojiLocation = useQuery('emojiLocation', () => {
    return getContentfulEmojiLocation()
  })

  const emojiStorePickup = useQuery('emojiStorePickup', () => {
    return getContentfulEmojiStorePickup()
  })

  const emojiStoreDelivery = useQuery('emojiStoreDelivery', () => {
    return getContentfulEmojiStoreDelivery()
  })

  const userCouponProps = couponProps({ level: user?.level })

  return (
    <>
      <LoginModalBrowse
        imageSrc={loginImageUrl}
        isOpen={loginModalOpen}
        onClose={handleCloseLoginModal}
        onForgotPasswordClick={handleOpenForgotPasswordModal}
      />
      <ForgotPasswordModalBrowse
        imageSrc={loginImageUrl}
        isOpen={forgotPasswordModalOpen}
        onBack={handleOpenLoginModal}
        onClose={handleCloseForgotPasswordModal}
      />

      <Container
        id='header__browse'
        isWrap
        position='sticky'
        zIndex={getGlobalStyle('--z-index-10')}
      >
        <Header
          accountTooltipProps={{
            onClose: handleAccountTooltipClose,
            primaryText: '¡Ya eres parte de Club Alvi.cl!',
            secondaryText:
              'Conoce aquí todo sobre tu cuenta y beneficios del ahorro.'
          }}
          addressSelected={addressSelected || 'Selecciona tu dirección'}
          aislesProps={{
            ...aislesPropsData,
            iconAisles: 'Menu',
            iconAislesClose: 'CloseThin',
            statusAisles: isVisibleAisles ? 'open' : 'close',
            title: 'Categorías',
            titleMobile: 'Nuestras categorías',
            isVisibleAislesIcon: true,
            iconSizeCategoriesMobile: 24,
            isVisibleAislesIconBurger: false
          }}
          cfDataHeader={cfDataHeader}
          colorQuantity={'blue'}
          couponProps={userCouponProps}
          customBannerUrl={myProfileBannerImageUrl}
          iconStoreLocator={iconStoreLocator}
          isAccountTooltipOpen={shouldAccountTooltipBeOpen}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          isVisibleCoupon={true}
          isVisibleCouponNew
          isVisibleModalLogout={isVisibleModalLogout}
          isVisibleMyProfile={false}
          linkOffers={offerLink.offerUrl}
          logo={'LogoHeaderAlvi'}
          onClickCart={handleClickCart}
          onClickCloseLogoutMobile={() => {
            setIsVisibleModalLogout(false)
          }}
          onClickCloseMyProfile={() => {
            setIsVisibleMyProfile(false)
          }}
          onClickCoupon={handleClickClub}
          onClickGoToMyCoupons={handleClickGoToMyCoupons}
          onClickGoToMyOrders={() => {
            router.push(process.env.NEXT_PUBLIC_MYORDERSURL)
          }}
          onClickGoToMyRequest={() => {
            return
          }}
          onClickLogin={handleOpenLoginModal}
          onClickLogout={handleLogout}
          onClickLogoutMobile={() => {
            setIsVisibleModalLogout(true)
          }}
          onClickMyProfile={handleClickMyProfile}
          onClickMyProfileBanner={() => {
            if (window.location.origin === process.env.NEXT_PUBLIC_MYORDERSURL)
              window.location.reload()
            router.push(`${process.env.NEXT_PUBLIC_MYORDERSURL}/benefits`)
          }}
          onClickStoreLocator={handleStoreLocator}
          quantity={useUpdateProps.totalQuantity}
          quantityLoading={isOrderFormLoading}
          searchProps={
            {
              backgroundGradientIconSearch: getGlobalStyle(
                '--color-alvi-primary-blue-gradient'
              ),
              iconArrowSearch: 'ArrowBackNavigate',
              ...searchPropsData
            } as SearchProps
          }
          showCouponTooltip={showCouponTooltip}
          site='alvi'
          sizeLogo={58}
          sizeLogoMobile={38}
          smallScreenSLVisible={smallScreenSLVisible}
          storeLocatorProps={{
            address: addressSelected,
            addressLabel: isPickup ? 'Retira en:' : 'Recibe en:',
            addressPicture: isPickup
              ? emojiStorePickup.data?.fields?.file?.url
              : emojiStoreDelivery.data?.fields?.file?.url,
            placeholder: 'Elige un método de entrega',
            placeholderLegend: 'Despacho a domicilio o retiro en tienda',
            placeholderPicture: emojiLocation.data?.fields?.file?.url,
            purchase: slaPrice,
            purchaseColor: isPickup
              ? getGlobalStyle('--color-background-jade')
              : null,
            purchasePicture: isPickup
              ? emojiCelebration.data?.fields?.file?.url
              : null,
            schedule: sla,
            scheduleLabel: 'Desde:'
          }}
          timeTable={sla}
          urlLogo={process.env.NEXT_PUBLIC_HOMEURL}
          userFirstName={data?.firstName || user?.firstName || 'Mi perfil'}
          userShabitName={name}
          validationStatus={validationStatus}
        />
        {isVisibleMyProfile && (
          <>
            <BigScreen>
              <Container
                margin='-35px auto 0'
                position='relative'
                width='1280px'
              >
                <span className={styles.popupCorner}></span>
                <DropDownMyProfile
                  cfDataHeader={cfDataHeader}
                  customBannerUrl={dropDownMenuImage?.imageUrl}
                  customClassName={styles.customPosition}
                  onClickBanner={dropDownMenuImage?.onClick}
                  onClickCloseMyProfile={() => {
                    setIsVisibleMyProfile(false)
                  }}
                  onClickGoToAskHere={handleClicks?.onClickGoToAskHere}
                  onClickGoToBussinessPartner={
                    handleClicks?.onClickGoToBussinessPartner
                  }
                  onClickGoToClubAlvi={handleClicks?.onClickGoToClubAlvi}
                  onClickGoToHelpCenter={handleClicks?.onClickGoToHelpCenter}
                  onClickGoToLocationsAndSchedules={
                    handleClicks?.onClickGoToLocationsAndSchedules
                  }
                  onClickGoToMyPersonalInformation={
                    handleClicks?.onClickGoToMyPersonalInformation
                  }
                  onClickGoToMyRequest={() => {
                    return
                  }}
                  onClickGoToMyShopping={handleClicks?.onClickGoToMyShopping}
                  onClickLogout={handleLogout}
                  onClickLogoutMobile={handleLogout}
                  site={site}
                />
              </Container>
            </BigScreen>
          </>
        )}

        <BigScreen>
          {(isVisible || isVisibleAisles || isVisibleMyProfile) && (
            <Overlay onClick={handleClose} />
          )}
        </BigScreen>
      </Container>
    </>
  )
}
